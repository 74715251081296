<template id="tmplCustomerMultiSelect">
  <div class="w-100 customer-multiselect" id="divCustomerMultiSelect">
    <multiselect
      :id="id"
      label="name"
      track-by="name"
      :placeholder="placeholder"
      open-direction="bottom"
      :options="customers"
      :value="getFilterSelectedCustomer"
      @input="setFilterSelectedCustomer"
      :searchable="true"
      :close-on-select="true"
      :max-height="600"
      :show-no-results="false"
    >
      <span slot="noResult" id="spnNoResult"
        >No Customers found. Consider changing the search query.</span
      >

      <template slot="clear" slot-scope="props" id="tmplFilterSelectCustomer">
        <div
          id="divFilterSelectCustomer"
          class="multiselect__clear"
          v-if="getFilterSelectedCustomer"
          @mousedown.prevent.stop="clearSelected(props.search)"
        ></div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const customersRepository = RepositoryFactory.get("customersRepository");

export default {
  name:"CustomerMultiSelect.vue",
  props: ["id", "initialCustomerId", "placeholder"],
  components: {
    Multiselect
  },
  data() {
    return {
      customers: []
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    ...mapActions("users", ["setFilterSelectedCustomer"]),
    async load(query) {
      const requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        searchTerm: query,
        skip: 0,
        take: 20
      };
      try {
        let response = await customersRepository.get(requestData);

        this.customers = response.data;

        if (this.initialCustomerId)
          this.setFilterSelectedCustomer(
            this.customers.find(x => x.id === this.initialCustomerId)
          );
      } catch (error) {
        console.log(error);
      }
    },
    clearSelected() {
      this.setFilterSelectedCustomer(null);
    }
  },
  computed: {
    ...mapGetters("users", ["getFilterSelectedCustomer"])
  }
};
</script>
<style scoped></style>
