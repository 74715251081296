<template>
  <div class="container vld-parent">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row>
      <h1 class="text-primary">Users</h1>
    </b-row>
    <b-row>
      <b-card style="width: 100%">
        <b-row class="mb-2">
          <b-col>
            <b-button
              v-if="canCreateUsers"
              variant="primary"
              @click="showUserSlideIn('Add User', null)"
            >
              <i class="fa fa-plus mr-1"></i> Add New User
            </b-button>
          </b-col>
        </b-row>

        <b-row align-h="between" class="my-3">
          <b-col md="3" class="my-1">
            <b-form inline>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search Users"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>

          <b-col md="3" class="my-1">
            <b-form inline class="float-md-right">
              <label class="mr-sm-2 col-form-label-sm" for="perPageSelect"
                >Per page:</label
              >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" class="my-1" v-if="!checkExternalUser">
            <div class="form-group row">
              <div class="col-sm-10">
                <CustomersMultiSelect
                  id="msCustomer"
                  :initialCustomerId="customerId"
                  placeholder="Filter Users by Customer"
                ></CustomersMultiSelect>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :items="allUsers"
              :fields="fields"
              responsive="sm"
              striped
              outlined
              :current-page="currentPage"
              :per-page="perPage"
              :filter-ignored-fields="ignoreFields"
              :filter="filter"
              @filtered="onFiltered"
              :sort-direction="sortDirection"
              :show-empty="true"
            >
              <template v-slot:cell(customerAdmin)="data">
                <b-form-checkbox
                  :disabled='true'
                  v-model="data.item.customerAdmin"
                >
                </b-form-checkbox>
              </template>

              <template v-slot:cell(customerName)="data">
                <b-button
                  v-if="canViewCustomers"
                  size="sm"
                  variant="link"
                  class="mb-1 mr-2"
                  id="btnEditUser"
                  @click="redirectToCustomer(data.item.customerId)"
                >
                  {{ data.item.customerName }}
                </b-button>
                <span v-else> {{ data.item.customerName }}</span>
              </template>

              <template v-slot:cell(actions)="data">
                <b-button
                  v-if="canUpdateUsers"
                  size="sm"
                  variant="primary"
                  class="mb-1 mr-2 btn-block-xs-only"
                  id="btnEditUser"
                  @click="showUserSlideIn('Edit User', data.item)"
                >
                  <i class="fa fa-edit mr-1"></i>
                  Edit
                </b-button>

                <b-button
                  v-if="canDeleteUsers"
                  size="sm"
                  variant="danger"
                  class="mb-1 btn-sm btn-block-xs-only"
                  style="cursor:pointer"
                  @click="showDeleteUserConfirm(data.item.id)"
                >
                  <i class="fa fa-trash mr-1"></i>
                  Delete
                </b-button>
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="my-2"
            ></b-pagination>
            Total Records : {{ totalRows }}
          </b-col>
        </b-row>
      </b-card>
    </b-row>
    <slideout-panel></slideout-panel>

    <b-modal
      v-model="showUserDeleteModal"
      title="Delete User"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      size="md"
      @hide="closeModal()"
    >
      <b-row>
        <b-col>
          <p>Are you sure you want to delete this user?</p>
        </b-col>
      </b-row>

      <div slot="modal-footer" class="w-100">
        <b-button
          class="float-right ml-2 mr-2"
          variant="primary"
          @click="confirmDeleteUser()"
          >Yes, Delete</b-button
        >
        <b-button
          class="float-right"
          variant="outline-danger"
          @click="closeModal()"
          >Do Not Delete</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { handleRequestError } from "@/shared/utils/response-error-handler";
import { isExternalUser } from "@/shared/utils/permissions";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CustomersMultiSelect from "@/components/customers/CustomersMultiSelect";
import {
	canCreateUsers,
  canUpdateUsers,
  canDeleteUsers,
  canViewCustomers
} from "@/shared/utils/permissions";
import { KagInternalCustomerId } from "@/shared/utils/permissions";

const UserRepository = RepositoryFactory.get("users");

export default {
  components: {
    Loading,
    CustomersMultiSelect
  },

  props: ["customerName", "loggedInUserId"],

  data() {
    return {
      isLoading: false,
      showUserDeleteModal: false,
      userToDelete: "",
      allUsers: [],
      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100, 500],
      filter: null,
      sortDirection: "asc",
      customerId: "",
      fields: [
        { key: "firstName", label: "First Name", sortable: true },
        { key: "lastName", label: "Last Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "customerName", label: "Customer", sortable: true },
        { key: "customerAdmin", label: "Admin", sortable: true },
        { key: "actions", label: "Actions" }
      ],
      ignoreFields: ["id"]
    };
  },

  async created() {
    let customerId = null;

    if (this.$route.query.customerId) {
      customerId = this.$route.query.customerId;
    } else if (this.getFilterSelectedCustomer) {
      customerId = this.getFilterSelectedCustomer.id;
    } else {
      customerId = this.determineCustomerId();
    }

    await this.fetch(customerId);
  },

  methods: {
    async fetch(customerId) {
      this.customerId = customerId;

      this.isLoading = true;

      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          id: this.customerId
        };

        var customerUsers = await UserRepository.get(requestData);

        this.allUsers = customerUsers.data.results;
        this.totalRows = this.allUsers.length;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.isLoading = false;
      }
    },

		determineCustomerId() {
			const isInternalCustomer = this.loggedInUser.customerId.toUpperCase() === KagInternalCustomerId.toUpperCase();
			return isInternalCustomer ? null : this.loggedInUser.customerId.toUpperCase();
		},

    showDeleteUserConfirm(userId) {
      this.showUserDeleteModal = true;
      this.userToDelete = userId;
    },

    closeModal() {
      this.showUserDeleteModal = false;
      this.userToDelete = "";
    },

    showUserSlideIn(title, user) {
      if (user == null) {
        this.$router.push({
          name: "admin-create-user"
        });

        return;
      }

      this.$router.push({
        name: "admin-edit-user",
        params: { id: user.id }
      });
      return;
    },

    redirectToCustomer(customerId) {
      this.$router.push({
        name: "admin-edit-customer",
        params: { id: customerId }
      });
      return;
    },

    async confirmDeleteUser() {
      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        userId: this.userToDelete
      };

      try {
        await UserRepository.delete(requestData);
        this.allUsers = this.allUsers.filter(w => w.id != this.userToDelete);

        this.$snotify.success(`User deleted successfully.`, "Success", {
          timeout: 1000,
          showProgressBar: true
        });

        this.totalRows = this.allUsers.length;
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      } finally {
        this.closeModal();
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  computed: {
    ...mapGetters({
			getFilterSelectedCustomer: "users/getFilterSelectedCustomer", // Getter from the "users" module
			loggedInUser: "user/loggedInUser" // Getter from the "users" module
		}),

    canCreateUsers() {
      return canCreateUsers(this.$store);
    },

    canUpdateUsers() {
      return canUpdateUsers(this.$store);
    },

    canDeleteUsers() {
      return canDeleteUsers(this.$store);
    },

    checkExternalUser() {
      return isExternalUser(this.$store);
    },

    canViewCustomers() {
      return canViewCustomers(this.$store);
    }
  },

  watch: {
    getFilterSelectedCustomer(newValue, oldValue) {
      let newCustomerId = null;
      if (newValue) newCustomerId = newValue.id;
      this.fetch(newCustomerId);
    }
  }
};
</script>
